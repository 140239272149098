import { Theme, useTheme } from '@mui/material/styles';
import {
  Box,
  SxProps,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/system';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

type Props = {
  tableCellStyle?: {};
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  testId?: string;
};

export const TableHeadCustom = ({
  tableCellStyle,
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
  testId,
}: Props) => {
  const theme = useTheme();
  const borderStyle = `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`;
  return (
    <TableHead sx={sx} data-sm={testId}>
      <TableRow>
        {onSelectAllRows && (
          <TableCell
            sx={{
              minWidth: '150px',
              backgroundColor: theme.palette.background.default,
              borderBottom: borderStyle,
            }}
            padding="checkbox"
          >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              ...tableCellStyle,
              backgroundColor: theme.palette.background.default,
              borderBottom: borderStyle,
            }}
            data-sm={headCell.testId}
          >
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onSort(headCell.id)}
                sx={{ textTransform: 'capitalize' }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              `${headCell.label || ''}`
            )}
            {headCell.subLabel && (
              <Typography
                sx={{ fontSize: '12px', fontWeight: '400', color: theme.palette.grey[500] }}
              >
                {headCell.subLabel}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
