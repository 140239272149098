import { Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { StyledReportsHeader, btnsBox, filedsBox, inputFiled } from './style';
import { FunctionComponent, useState } from 'react';
import { useResponsive } from 'src/hooks';
import { useAuthentication } from 'src/features/authentication/context';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { RHFAutocomplete } from 'src/components/minimals/form';
import { ApiResourceState } from '../../types';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { useTemplateState } from '../../context/templateContext';
import { ReportsService } from 'src/services';
import { parseReportTemplatePayload } from '../../utils/parseReportTemplatePayload';
import { useTemplateCache } from '../../context/templateCacheContext';
import { useFilterSites } from '../../context/sitesContext';
import { ACCESS_ROLES } from 'src/config';

export const ReportsHeader: FunctionComponent<{
  title: string;
  disableActions: boolean;
  onUndo: (sites: any) => void;
}> = ({ title, onUndo, disableActions }) => {
  const theme = useTheme();
  const methods = useFormContext();
  const { get } = useQueryParamsActions();
  const tempalteId = get('templateId');
  const { checkTemplateForChanges } = useTemplateState();
  const stale = checkTemplateForChanges(methods.watch());
  const isTablet = useResponsive('down', 'lg');
  const { enqueueSnackbar } = useSnackbar();
  const [reportState, setReportState] = useState<{
    status: ApiResourceState;
    error: Error | null;
    data: any;
  }>({
    status: 'INITIAL',
    error: null,
    data: null,
  });

  const { getValues, watch } = useFormContext();
  const { get: getQueryParam, append } = useQueryParamsActions();
  const templateId = getQueryParam('templateId');
  const labelStyle = 'original';
  const { isLoading, sites } = useFilterSites();
  const { options } = useTimezoneSelect({ labelStyle, timezones: allTimezones });
  const templateCacheState = useTemplateCache();

  const {
    getCurrentRole,
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const currentRole = getCurrentRole(customerId, siteId);
  const isSuperAdmin = currentRole === ACCESS_ROLES.SUPER_ADMIN;
  const isAccountAdmin = currentRole === ACCESS_ROLES.ACCOUNT_ADMIN;
  const isSiteManager = currentRole === ACCESS_ROLES.SITE_MANAGER;

  const disabledPermisionSaveBtn = !(isSuperAdmin || isAccountAdmin || isSiteManager);

  const onSaveHandler = async () => {
    const data = getValues();

    if (!data.header.timeZone) {
      return enqueueSnackbar('Timezone is required!', { variant: 'error' });
    }
    setReportState((prevState) => ({ ...prevState, status: 'LOADING' }));

    try {
      const report = templateId
        ? await ReportsService.updateReportTemplate(
            customerId!,
            parseReportTemplatePayload(data.header.reportType?.id, {
              ...data,
              title,
            }),
            templateId
          )
        : await ReportsService.saveReportTemplate(
            customerId!,
            parseReportTemplatePayload(data.header.reportType?.id, { ...data, title })
          );
      templateCacheState[1]({});
      append('templateId', report.id);
      setReportState((prevState) => ({ ...prevState, data: report, status: 'SUCCESS' }));
      enqueueSnackbar('Report template successfully ' + (templateId ? 'updated' : 'saved') + '!', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(
        'Could not ' + (templateId ? 'update' : 'save') + ' report template!\n' + err.message,
        { variant: 'error' }
      );
      setReportState((prevState) => ({ ...prevState, error: err, status: 'ERROR' }));
    }
  };

  return (
    <StyledReportsHeader theme={theme} isTablet={isTablet}>
      <Stack flexDirection="column" sx={{ ...filedsBox, alignItems: isTablet ? 'center' : '' }}>
        <Typography fontWeight="700" variant="body1">
          {watch('header.reportType')?.label || 'Loading...'}
        </Typography>
        <RHFAutocomplete
          sx={{ ...inputFiled, width: isTablet ? '100%' : '280px' }}
          name="header.timeZone"
          rules={{ required: 'The field is required' }}
          options={options}
          disableClearable
          size="small"
          renderInput={(params) => <TextField {...params} fullWidth label={'Time Zone *'} />}
        />
      </Stack>

      <Stack flexDirection="row" justifyContent="center" sx={btnsBox}>
        {(tempalteId && (stale === 'INVALID' || !stale)) || (!tempalteId && !stale) ? (
          <Button
            disabled={disableActions || isLoading}
            onClick={() => onUndo(sites)}
            size="medium"
            variant="outlined"
          >
            Undo Changes
          </Button>
        ) : (
          <></>
        )}
        <Button
          disabled={
            reportState.status === 'LOADING' ||
            stale === 'INVALID' ||
            stale ||
            disableActions ||
            isLoading ||
            disabledPermisionSaveBtn
          }
          size="medium"
          variant="contained"
          onClick={onSaveHandler}
        >
          {reportState.status === 'LOADING' ? 'Saving template...' : 'Save Template'}
        </Button>
      </Stack>
    </StyledReportsHeader>
  );
};
