import { Box, useTheme, Typography } from '@mui/material';
import { RemoveCycleIcon } from 'src/assets';

type Props = {
  title: string;
  onDelete: () => void;
};

export const SelectedFilterPill = ({ title, onDelete }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.grey[300],
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '50px',
        padding: '3px 5px',
      }}
    >
      <Typography sx={{ fontSize: '13px' }}>{title}</Typography>
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '2px',
        }}
        onClick={onDelete}
      >
        <RemoveCycleIcon />
      </Box>
    </Box>
  );
};
