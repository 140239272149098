import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

const useQueryParamsActions = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(search);

  const _navigateWithNewParams = () => navigate(`${location.pathname}?${searchParams.toString()}`);

  const params = searchParams.toString();
  const navigateWithParams = useCallback(
    (route: string) => navigate(`${route}?${params}`),
    [navigate, params]
  );

  const append = (key: string, value: string) => {
    searchParams.set(key, value);
    _navigateWithNewParams();
  };

  const remove = (key: string) => {
    searchParams.delete(key);
    _navigateWithNewParams();
  };

  const get = (key: string) => searchParams.get(key);

  return { append, get, params, remove, navigateWithParams };
};

export default useQueryParamsActions;
