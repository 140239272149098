import { Box, Button, Stack, SvgIcon, useTheme, Typography } from '@mui/material';
import { ChangeEvent, FunctionComponent, useRef, useState } from 'react';
import { AttachmentField as AttachmentFieldType } from '../types';
import { useFormContext } from 'react-hook-form';
import SectionTitle from './SectionTitle';
import { BlockStack } from '../styles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImagePreviewBox from 'src/components/streametric/image-preview-box/ImagePreviewBox';

const AttachmentField: FunctionComponent<{
  field: AttachmentFieldType;
}> = ({ field }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setValue, watch } = useFormContext();
  const theme = useTheme();
  const files = watch(field.id) || [];
  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);

  const imageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'image/svg+xml',
    'image/bmp',
    'image/tiff',
  ];

  const itemStyles = {
    boxShadow: 2,
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px',
    overflow: 'hidden',
    flexShrink: '0',
    width: '100px',
    height: '100px',
  };

  const handleUploadFile = (event: ChangeEvent) => {
    const { files } = event.target as HTMLInputElement;
    const filesArray = Array.from(files ? files : []);

    setValue(field.id, filesArray);
  };

  return (
    <BlockStack>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <SectionTitle>Attachments</SectionTitle>
        <Button
          variant="outlined"
          onClick={() => {
            if (inputRef.current) inputRef.current.click();
          }}
        >
          Add attachment
        </Button>
      </Stack>
      <input
        multiple
        onChange={handleUploadFile}
        type="file"
        accept={
          field.validations?.allowed_mime_types
            ? field.validations?.allowed_mime_types.join(',')
            : undefined
        }
        ref={inputRef}
        style={{ display: 'none' }}
      />
      {typeof currentImageIndex === 'number' ? (
        <ImagePreviewBox
          onClose={() => {
            setCurrentImageIndex(null);
          }}
          renderContentItem={(file) =>
            imageMimeTypes.includes(file.type) ? (
              <img
                width="100%"
                height="100%"
                style={{ objectFit: 'contain' }}
                src={URL.createObjectURL(file)}
                alt="preview"
              />
            ) : (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <SvgIcon fontSize="large" component={InsertDriveFileIcon} />
                <Typography sx={{ marginTop: theme.spacing(1), width: '80%' }} variant="body1">
                  {file.name}
                </Typography>
              </Stack>
            )
          }
          currentImageOrder={currentImageIndex}
          content={files}
        />
      ) : (
        <></>
      )}
      <Stack
        flex="1"
        sx={{
          overflow: 'auto',
          width: '100%',
          minHeight: '100px',
          maxWidth: '100%',
          marginTop: theme.spacing(2),
        }}
        flexDirection="row"
        gap={theme.spacing(1)}
      >
        {files.map((file: File, index: number) =>
          imageMimeTypes.includes(file.type) ? (
            <Box
              onClick={() => {
                setCurrentImageIndex(index);
              }}
              key={file.name}
              sx={itemStyles}
            >
              <img alt="preview" src={URL.createObjectURL(file)} />
            </Box>
          ) : (
            <Stack
              key={file.name}
              onClick={() => {
                setCurrentImageIndex(index);
              }}
              alignItems="center"
              justifyContent="center"
              sx={itemStyles}
            >
              <SvgIcon fontSize="large" component={InsertDriveFileIcon} />
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  width: '60%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                variant="caption"
              >
                {file.name}
              </Typography>
            </Stack>
          )
        )}
      </Stack>
    </BlockStack>
  );
};

export default AttachmentField;
