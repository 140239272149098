import { Container, Stack } from '@mui/material';
import { ReportPreview, SideBarFilter } from '../components';
import { useResponsive } from 'src/hooks';
import { useFilters } from '../hooks';
import AssetReportsSidebar from '../components/asset-report-sidebar/AssetReportsSidebar';
import useReportPreviewState from '../hooks/useReportPreviewState';
import ReportPreviewLoading from '../components/report-preview-loading/ReportPreviewLoading';
import NoReportGeneratedComponent from '../components/no-report-generated/NoReportGeneratedComponent';
import { useAuthentication } from 'src/features/authentication/context';

export const AssetReportsPage = () => {
  const isTablet = useResponsive('down', 'lg');
  const { state, handleReportCancel, handleReportsPreview } = useReportPreviewState();
  const {
    isOpen,
    toggleSidebar,
    applyFiltersHandler,
    appliedFilters,
    isFilterBtnActive,
    clearAllFiltersHandler,
    isInitialState,
  } = useFilters();
  const { sites } = useAuthentication();

  let content;
  if (state.status === 'LOADING') {
    content = <ReportPreviewLoading onCancel={handleReportCancel} />;
  } else if (!state.data) {
    content = <NoReportGeneratedComponent />;
  } else {
    content = <ReportPreview data={state.data} title={`${state.data.reportName}`} />;
  }

  return (
    <Container
      maxWidth={false}
      disableGutters
      data-testId="reports-page"
      sx={{ paddingBottom: '1rem' }}
    >
      <SideBarFilter
        isOpen={isOpen}
        isInitialState={isInitialState}
        sites={sites}
        toggleSidebar={toggleSidebar}
        applyFiltersHandler={applyFiltersHandler}
        appliedFilters={appliedFilters}
      />
      <Stack
        flexDirection={isTablet ? 'column' : 'row'}
        gap="24px"
        flex="1"
        sx={{
          marginTop: '24px',
        }}
      >
        <AssetReportsSidebar
          applyFiltersHandler={applyFiltersHandler}
          toggleSidebar={toggleSidebar}
          onSubmit={handleReportsPreview}
          isFilterBtnActive={isFilterBtnActive}
          clearAllFiltersHandler={clearAllFiltersHandler}
        />
        {content}
      </Stack>
    </Container>
  );
};
