import { Table, TableBody, TableContainer, Card, useTheme, Box } from '@mui/material';
import { TableHeadCustom, MemoizedScrollbar, useTable } from 'src/components';
import { ReportPreviewHeader } from '../report-preview-header';
import { TableFooterComponent } from '../table-footer-component';
import { DataReportTableRowItem } from '../data-report-table-row-item';
import { useResponsive } from 'src/hooks';
import { useEffect, useState } from 'react';

type Props = {
  title: string;
  data: any;
  onlyExport?: boolean;
  isHistoryPage?: boolean;
  reportId?: string;
};

export const ReportPreview = ({
  onlyExport = false,
  title,
  data,
  isHistoryPage,
  reportId,
}: Props) => {
  const theme = useTheme();
  const table = useTable();
  const lightGreyColor = theme.palette.grey[200];
  const isMobile = useResponsive('down', 'xl');
  const [rows, setRows] = useState<any>(data.data || []);

  useEffect(() => {
    setRows(data.data || []);
  }, [data, table.page, setRows, table.rowsPerPage]);

  const content = (
    <Card sx={{ width: '100%', minWidth: '1200px' }}>
      <ReportPreviewHeader
        onlyExport={onlyExport}
        title={title}
        instanceId={data.id}
        isHistoryPage={isHistoryPage}
        reportId={reportId}
      />
      <TableContainer>
        <MemoizedScrollbar sx={{ maxHeight: '450px', overflow: 'auto' }}>
          <Table size="medium" data-testId="reports-table">
            <TableHeadCustom
              tableCellStyle={{ background: `${lightGreyColor} !important` }}
              headLabel={data.columns.map((column: any) => ({
                name: column.key,
                label: [
                  column.value,
                  column.aggregation,
                  column.unit ? (column.unit === 'null' ? false : column.unit) : false,
                ]
                  .filter(Boolean)
                  .join(' - '),
              }))}
            />

            <TableBody data-testId="table-reports-content">
              {rows?.map((row: any) => (
                <DataReportTableRowItem columns={data.columns} key={row.id} row={row} />
              ))}
            </TableBody>
            <TableFooterComponent columns={data.columns} data={data.statistics} />
          </Table>
        </MemoizedScrollbar>
      </TableContainer>
    </Card>
  );
  return isMobile ? <Box sx={{ width: '100%', overflow: 'auto' }}>{content}</Box> : content;
};
