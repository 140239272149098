import { useMemo } from 'react';
import { Box } from '@mui/material';
import { SelectedFilterPill } from '../selected-filter-pill';
import { ASSET, SEPARATOR, initialFilters } from '../../config/config';
import { sitesTypes, assetTypes } from './variables';
import { useFormContext } from 'react-hook-form';
import { useFilterSites } from '../../context/sitesContext';

export const SelectedFiltersList = ({ applyFiltersHandler }: { applyFiltersHandler: any }) => {
  const { watch, setValue } = useFormContext();

  const { sites } = useFilterSites();
  const appliedFilters = watch('filters') as typeof initialFilters;
  const lengthOfSelectedAssetTypes = appliedFilters.assetTypes.length;
  const lengthOfSelectedSitesTypes = appliedFilters.sitesTypes.length;

  const selectedAssets = useMemo(
    () => appliedFilters.selectedItems.filter((item) => item.includes(ASSET)),
    [appliedFilters]
  );
  const selectedSites = useMemo(
    () => appliedFilters.selectedItems.filter((item) => item && item.substring(0, 4) === 'site'),
    [appliedFilters]
  );

  const assetTypesInitialLength = initialFilters.assetTypes.length;
  const sitesTypesInitialLength = initialFilters.sitesTypes.length;

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginBottom: '10px',
      }}
    >
      {!appliedFilters.selectedItems.filter((item) => item.includes('site')).length && (
        <SelectedFilterPill
          title={'Sites: 0'}
          onDelete={() =>
            applyFiltersHandler({
              ...initialFilters,
              selectedItems: [...sites, ...selectedAssets],
            })
          }
        />
      )}
      {lengthOfSelectedAssetTypes > 2 && lengthOfSelectedAssetTypes < assetTypesInitialLength && (
        <SelectedFilterPill
          title={`Asset Type: ${lengthOfSelectedAssetTypes}`}
          onDelete={() => {
            setValue('filters', { ...appliedFilters, assetTypes: initialFilters.assetTypes });
          }}
        />
      )}
      {lengthOfSelectedAssetTypes < 3 &&
        appliedFilters?.assetTypes?.map((assetType) => {
          // @ts-ignore
          let pillTitle = assetTypes[assetType] ?? assetType;
          const newAssetState = appliedFilters.assetTypes.filter((item) => item !== assetType);
          return (
            <SelectedFilterPill
              key={assetType}
              title={pillTitle}
              onDelete={() => {
                setValue('filters', {
                  ...appliedFilters,
                  assetTypes:
                    newAssetState.length === 0 ? initialFilters.assetTypes : newAssetState,
                });
              }}
            />
          );
        })}

      {lengthOfSelectedSitesTypes > 2 && lengthOfSelectedSitesTypes < sitesTypesInitialLength && (
        <SelectedFilterPill
          title={`Site Type: ${lengthOfSelectedSitesTypes}`}
          onDelete={() => {
            setValue('filters', {
              ...appliedFilters,
              sitesTypes: initialFilters.sitesTypes,
            });
          }}
        />
      )}
      {lengthOfSelectedSitesTypes < 3 &&
        appliedFilters?.sitesTypes?.map((siteType) => {
          // @ts-ignore
          let pillTitle = sitesTypes[siteType] ?? siteType;
          const newSitesState = appliedFilters.sitesTypes.filter((item) => item !== siteType);
          return (
            <SelectedFilterPill
              key={siteType}
              title={pillTitle}
              onDelete={() => {
                setValue('filters', {
                  ...appliedFilters,
                  sitesTypes:
                    newSitesState.length === 0 ? initialFilters.sitesTypes : newSitesState,
                });
              }}
            />
          );
        })}

      {selectedSites.length !== sites.length ? (
        selectedSites?.length > 2 ? (
          <SelectedFilterPill
            title={`Site Name: ${selectedSites?.length}`}
            onDelete={() => {
              setValue('filters', {
                ...appliedFilters,
                selectedItems: selectedAssets,
              });
            }}
          />
        ) : (
          selectedSites.map((site) => {
            const splitedSite = site.split(SEPARATOR);
            const newState = appliedFilters.selectedItems.filter((item) => item !== site);
            return (
              <SelectedFilterPill
                key={site}
                title={splitedSite[1]}
                onDelete={() => {
                  setValue('filters', {
                    ...appliedFilters,
                    selectedItems: newState,
                  });
                }}
              />
            );
          })
        )
      ) : (
        <></>
      )}

      {selectedAssets?.length > 2 ? (
        <SelectedFilterPill
          title={`Asset Name: ${selectedAssets?.length}`}
          onDelete={() => {
            setValue('filters', {
              ...appliedFilters,
              selectedItems: selectedSites,
            });
          }}
        />
      ) : (
        selectedAssets.map((asset) => {
          const splitedAsset = asset.split(SEPARATOR);
          const newState = appliedFilters.selectedItems.filter((item) => item !== asset);
          return (
            <SelectedFilterPill
              key={asset}
              title={splitedAsset[1]}
              onDelete={() => {
                setValue('filters', {
                  ...appliedFilters,
                  selectedItems: newState,
                });
              }}
            />
          );
        })
      )}
    </Box>
  );
};
